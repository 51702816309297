import React from "react";
import { Link } from "gatsby";
import LIST_OF_FUNDING_METHODS from "../../images/siteImages/home/list-fund.svg";
import FAST_WITHDRAWAL from "../../images/siteImages/home/fast-withdrawal.svg";
import ACCOUNTS_COMPARISON from "../../images/siteImages/home/account.svg";
import CUSTOMER_SUPPORT from "../../images/siteImages/home/customer-support.svg";
import TRADING_ACADEMY from "../../images/siteImages/home/graduation-cap.svg";
// import LIST_OF_FUNDING_METHODS from "../../images/siteImages/home/LIST_OF_FUNDING_METHODS.png";
// import FAST_WITHDRAWAL from "../../images/siteImages/home/FAST_WITHDRAWAL.png";
// import ACCOUNTS_COMPARISON from "../../images/siteImages/home/ACCOUNTS_COMPARISON.png";
// import CUSTOMER_SUPPORT from "../../images/siteImages/home/CUSTOMER_SUPPORT.png";
// import TRADING_ACADEMY from "../../images/siteImages/home/TRADING_ACADEMY.png";

const WhyRoxImages = [
  LIST_OF_FUNDING_METHODS,
  FAST_WITHDRAWAL,
  ACCOUNTS_COMPARISON,
  CUSTOMER_SUPPORT,
  TRADING_ACADEMY,
];

const JoinAccuIndex = ({
  why_boxes = [],
  why_description = {},
  why_text = {},
}) => {
  return (
    <>
      <section className="pad50">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 whyContent">
              <h3 style={{ color: "#155291" }}>{why_text.text}</h3>
              <p className="description">{why_description.text}</p>
            </div>
          </div>
          <div className="row justify-content-center">
            {why_boxes.map((obj, whyIndex) => (
              <div
                key={"sdf" + whyIndex}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="whyContentBox text-center">
                  {obj.why_is_external === false && (
                    <Link to={obj.why_link.text}>
                      <span className="icon-rounded">
                        <img src={WhyRoxImages[whyIndex]} alt="rounded" />
                      </span>
                    </Link>
                  )}
                  {obj.why_is_external === true && (
                    <a
                      target="_blank"
                      href={obj.why_link.text}
                      rel="noreferrer"
                    >
                      <span className="icon-rounded">
                        <img src={WhyRoxImages[whyIndex]} alt="rounded" />
                      </span>
                    </a>
                  )}
                  <h6>{obj.why_title.text}</h6>
                  <p>{obj.why_description1.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinAccuIndex;
