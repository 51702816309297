import * as React from "react";
import { useSelector } from "react-redux";

import { Subscription } from "../components/common";
// import SEO from '../components/seo'
import {
  CarouselTop,
  JoinAccuIndex,
  Offers,
  TradingPlatforms,
  WhyAccuIndex,
  Popup,
} from "../components/home";

import Layout from "../layout";

import { useHomePageData } from "../prismic/staticQueries";
import OurAwards from "./Company/OurAwards.js";

const IndexPage = () => {
  const language = useSelector((state) => state.language);
  const homePageData = useHomePageData(language);
  const { show_price_feed = false } = homePageData;

  /**
   * We could move the SEO component for all pages
   */
  return (
    <Layout {...homePageData}>
      {/* <SEO
        {...homePageData}
      /> */}
      <>
        {/* <Popup {...homePageData} /> */}
        <CarouselTop {...homePageData} />
        {/* {show_price_feed === true && (
          <iframe
            title="iframe"
            src="https://fxpricing.com/fx-widget/ticker-tape-ask-bid-widget.php?id=1,13,39,18,20,1975,19,1984&widget_key=tgid5RkgVh3thZcZikbhVwFe&border=hide"
            style={{ height: "67px" }}
          ></iframe>
        )} */}
        <JoinAccuIndex {...homePageData} />
        <WhyAccuIndex {...homePageData} />
        <hr />
        <TradingPlatforms {...homePageData} />
        <hr />
        <Offers {...homePageData} />
        
      </>
    </Layout>
  );
};

export default IndexPage;
