import React from "react";
import BONUS_OFFER from "../../images/siteImages/home/bonus.png";
import PARTNERSHIP_OFFER from "../../images/siteImages/home/partnership.png";
import REFER_FRIEND from "../../images/siteImages/home/refer.png";
import DEMO_CONTEST from "../../images/siteImages/home/democontest.png";

const OFFERIMAGES = [BONUS_OFFER, PARTNERSHIP_OFFER, REFER_FRIEND, DEMO_CONTEST];
const JoinAccuIndex = ({
  latest_offer = {},
  latest_offer_description = {},
  latest_offers = [],
}) => {
  return (
    <>
      <section className="mb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offers">
              <h2 style={{ color: "#155291", paddingTop: "25px" }}>
                {latest_offer.text}
              </h2>
              <p>{latest_offer_description.text}</p>
            </div>
            <div className="offerCards col-lg-12">
              <div className="row">
                {latest_offers.map((offer, offerIndex) => (
                  <div className="col-lg-3 col-sm-6" key={"sdf" + offerIndex}>
                    <div className="card offerCard">
                      <figure>
                        <img src={OFFERIMAGES[offerIndex]} alt="ddd" />
                      </figure>
                      <div className="card-body">
                        <h6> {offer.offer_title.text}
                          {/* <a
                            href={offer.offer_link.text}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {offer.offer_title.text}
                          </a> */}
                        </h6>
                        <p>{offer.offer_description.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinAccuIndex;
