import React, { useEffect } from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import { PrismicRichText } from "@prismicio/react";
import { Carousel } from "react-bootstrap";
import MOBILE_OIL_GOLD from "../../images/siteImages/home/mobile-oil.png";
import MAP_BANNER from "../../images/siteImages/home/map-banner.png";
import COPYTRADING_BANNER from "../../images/siteImages/home/copytrading-bannner.png";
import PAMM_BANNER from "../../images/siteImages/home/pamm-banner.png";
import DEMO_CONTEST_BANNER from "../../images/siteImages/home/prize-3.png";
import DUBAI_BANNER from "../../images/siteImages/home/dubai-banner-2.png";
import TROPHY_BANNER from "../../images/siteImages/home/trophy.png";
import WELCOME_BONUS from "../../images/siteImages/home/welcome-bonus.png";
import StocksIcons from "../../pages/DemoContestAll/Components/StocksIcons";

import BannerBg1 from "../../images/siteImages/home/trading-bg.png";
import DemoContestBanner from "../../images/siteImages/home/demo-banner-bg.png";

const SliderImages = [TROPHY_BANNER, DEMO_CONTEST_BANNER, MOBILE_OIL_GOLD, MAP_BANNER, COPYTRADING_BANNER, PAMM_BANNER, WELCOME_BONUS];
const banner_bg = [BannerBg1, DemoContestBanner, BannerBg1, BannerBg1, BannerBg1, BannerBg1, BannerBg1];

const CarouselTop = ({ slider = [] }) => {
  const language = useSelector((state) => state.language);
  let isRtl;
  language === "ar-ae" && (isRtl = true);
  language === "so-kr" && (isRtl = true);
  language === "fa-ir" && (isRtl = true);

  const getBannerClass = (position) => {
    if (position === "Left") {
      return "container d-flex flex-row-reverse";
    }
    if (position === "Right") {
      return "container d-flex flex-row";
    }
  };

  // UseEffect to load and initialize the Finlogix widget
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.finlogix.com/Widget.js';
    script.async = true;

    script.onload = () => {
      if (window.Widget) {
        window.Widget.init({
          widgetId: '8b1060c5-7c7e-49ec-a5cd-c669e41c2789',
          type: 'StripBar',
          language: 'en',
          symbolPairs: [
            {
              symbolId: "19",
              symbolName: "EUR/USD"
            },
            {
              symbolId: "25",
              symbolName: "GBP/USD"
            },
            {
              symbolId: "36",
              symbolName: "USD/JPY"
            },
            {
              symbolId: "32",
              symbolName: "USD/CHF"
            },
            {
              symbolId: "44",
              symbolName: "XAU/USD"
            },
            {
              symbolId: "52",
              symbolName: "SP500"
            },
            {
              symbolId: "45",
              symbolName: "WTI"
            },
            {
              symbolId: "10007",
              symbolName: "Apple"
            },
            {
              symbolId: "10014",
              symbolName: "Tesla"
            },
            {
              symbolId: "10022",
              symbolName: "Netflix"
            }
          ],
          isAdaptive: true,
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up on unmount
    };
  }, []);

  return (
    <>
      <Carousel fade={true}>
        {slider.map((obj, index) => (
          <Carousel.Item key={index} style={{ backgroundImage: "url(" + banner_bg[index] + ")" }}>
            <div className={`${getBannerClass(obj.content_position)}`}>
              <div className="banner-side-img">
                {index === 2 && <StocksIcons />}
                {index > 2 && (
                  <img
                    className="d-block floating"
                    src={SliderImages[index - 1]}
                    alt="First slide"
                  />
                )}
                {(index === 0 || index === 1) && (
                  <img
                    className="d-block floating"
                    src={SliderImages[index]}
                    alt="First slide"
                  />
                )}
              </div>

              <Carousel.Caption style={{ width: "50%" }}>
                <div className="container slide-caption">
                  <div className="cta-content">
                    {ctaHeadingRender(obj)}
                    <PrismicRichText field={obj.cta_content.richText} />
                    {ctaButtonRender(obj, isRtl)}
                  </div>
                </div>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Finlogix Widget Container */}
      <div className="finlogix-container"></div>
    </>
  );
};

const ctaHeadingRender = (sliderObj) => {
  const headObj = sliderObj.cta_heading.text.split("<br>");
  return (
    <h2>
      {headObj.map((hText, index) => {
        let stxt = hText;
        stxt = stxt.replace(/<b>/, "~~");
        stxt = stxt.replace(/<\/b>/, "~~");
        const headText = stxt.split("~~");
        const hTxt = headText.map((txt, ii) => {
          const tag = ii + 1;
          if (headText.length === 1) {
            return <>{txt}</>;
          }
          if (tag % 2 === 0) {
            return <span className="boldTxt">{txt}</span>;
          }
          return <>{txt}</>;
        });
        if (headObj.length === 1) {
          return <>{hTxt}</>;
        }
        if (index < headObj.length - 1) {
          return (
            <>
              {hTxt} <br />
            </>
          );
        }
        return (
          <span key={index} className="highlight">
            {hTxt}{" "}
          </span>
        );
      })}
    </h2>
  );
};

const ctaButtonRender = (sliderObj, isRtl) => {
  if (sliderObj.open_blank) {
    return (
      <a
        href={sliderObj.cta_link.text}
        target="_blank"
        rel="noreferrer"
        className={isRtl ? "left" : "right"}
      >
        {sliderObj.cta_label.text}
      </a>
    );
  }
  return (
    <Link to={sliderObj.cta_link.text} target="_blank" className={isRtl ? "left" : "right"}>
      {sliderObj.cta_label.text}
    </Link>
  );
};

export default CarouselTop;