import React from "react"
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby"
import { registerDemoUrl, registerLiveUrl } from '../../utils/constants'

const JoinAccuIndex = ({
  demo_account_label = [],
  join_heading = {},
  live_account_label = [],
  text_below_demo_and_live_buttons = {},
}) => {
  return (
    <>
      <section className="pad50">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 joinContent">
              <h1 style={{ color: "#155291" }}>{join_heading.text}</h1>
              <div className="create-accounts mb-4">
                <Link className="btn btn-block" to={registerDemoUrl} style={{width:'250px'}}>
                  <button type="button" className="btn btn-block-blue">
                    {demo_account_label.text}
                  </button>
                </Link>
                <Link className="btn btn-block" to={registerLiveUrl} style={{width:'250px'}}>
                  <button type="button" className="btn btn-block-orange">
                    {live_account_label.text}
                  </button>
                </Link>
              </div>
              <div className="description">
                <PrismicRichText field={text_below_demo_and_live_buttons.richText} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
