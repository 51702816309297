import React, { useState } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import { Link } from "gatsby";
import POPUP_BANNER from "../../images/siteImages/home/POPUP_BANNER.png";

function Popup({ show_popup, source, popup_image }) {
  const [show, setShow] = useState(show_popup);
  const handleClose = () => setShow(false);

  return show_popup && popup_image ? (
    <Modal show={show} onHide={handleClose} centered className="homePopup">
      <CloseButton onClick={handleClose} />
      <Link
        to={`https://my.roxcapitals.com/en/register/forex/live/${
          source ? "?utm=" + source : ""
        }`}
      >
        <img src={POPUP_BANNER} alt={popup_image.alt} />
      </Link>
    </Modal>
  ) : (
    <></>
  );
}

export default Popup;
