import React, { useState } from "react";
import { Link } from "gatsby";
import { registerLiveUrl } from "../../utils/constants";
import THE_VIDEO from "../../assets/videos/service-center.mp4";
import MT4DEVICES from "../../images/siteImages/home/MT5DEVICES.png";
import ANDROID_ICON from "../../images/siteImages/home/ANDROID_ICON.png";
import APPLE_ICON from "../../images/siteImages/home/APPLE_ICON.png";
import WINDOWS_ICON from "../../images/siteImages/home/WINDOWS_ICON.png";
import FOREX_CURRENCY from "../../images/siteImages/home/FOREX_CURRENCY.png";
import FOREX_CURRENCY_FRONT from "../../images/siteImages/home/FOREX_CURRENCY_FRONT.png";
import CFD_BULLION from "../../images/siteImages/home/CFD_BULLION.png";
import CFD_INDICES from "../../images/siteImages/home/CFD_INDICES.png";
import CFD_ENERGY from "../../images/siteImages/home/CFD_ENERGY.png";
import CFD_BULLION_FRONT from "../../images/siteImages/home/CFD_BULLION_FRONT.png";
import CFD_INDICES_FRONT from "../../images/siteImages/home/CFD_INDICES_FRONT.png";
import CFD_ENERGY_FRONT from "../../images/siteImages/home/CFD_ENERGY_FRONT.png";
import BEAR_BULLS from "../../images/siteImages/home/BEAR_BULLS.jpg";

const MT4IMAGES = [ANDROID_ICON, APPLE_ICON, WINDOWS_ICON];

const TRADINGOPTIONIMAGES = [
  FOREX_CURRENCY,
  CFD_BULLION,
  CFD_INDICES,
  CFD_ENERGY,
];

const TRADINGOPTIONFRONTIMAGES = [
  FOREX_CURRENCY_FRONT,
  CFD_BULLION_FRONT,
  CFD_INDICES_FRONT,
  CFD_ENERGY_FRONT,
];

const JoinAccuIndex = ({
  trading_box = [],
  trading_description = {},
  trading_icon = {},
  trading_title = {},
  application_links = [],

  wide_choice = {},
  wide_range_description = {},
  wide_range_features = [],
  wide_range_thumbnail = {},

  next_level = {},
  next_level_description = {},
  next_level_thumbnail = {},
  next_level_button_label = {},
}) => {
  const [featuresIcons, setFeaturesIcons] = useState(
    TRADINGOPTIONIMAGES.map((feature) => feature)
  );
  return (
    <>
      <section className="content-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-0 order-sm-1">
                  <div className="tradingPlatforms">
                    <div className="marTop40">
                      <h2>{trading_title.text}</h2>
                      <p>{trading_description.text}</p>
                      <ul className="col-lg-10 col-sm-12">
                        {trading_box.map((obj, tradBoxInd) => (
                          <li
                            className="li-metaTrade "
                            key={"dfsdf" + tradBoxInd}
                          >
                            {/* <img
                              alt="ddd"
                              src={obj.trading_tile.url}
                              className="img-fluid"
                            /> */}
                            {/* {JSON.stringify(obj)} */}
                            <a className="btn btn-shaded-blue"
                            href={obj.trading_url.text}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <button
                              className="btn btn-shaded-blue"
                              style={{ fontSize: "1.25rem", fontWeight: "600" }}
                            > */}
                              {obj.trading_text.text}
                            {/* </button> */}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <div className="applicationLinks col-lg-10 col-sm-12">
                        {application_links.map((obj, appLinkInd) => (
                          <a
                            href={obj.link_url.text}
                            target="_blank"
                            rel="noreferrer"
                            key={"dfsdf" + appLinkInd}
                          >
                            <img
                              alt="ddd"
                              src={MT4IMAGES[appLinkInd]}
                              className="img-fluid"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 order-lg-1 order-sm-0">
                  <img src={MT4DEVICES} alt="image" />
                </div>
              </div>
            </div>

            <div className="col-lg-8 wideChoices marTop40">
              <h4>{wide_choice.text}</h4>
              <p>{wide_range_description.text}</p>
            </div>
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-1"></div>
                <div className="col-md-6">
                  <video width="100%" autoPlay={true} loop={true} muted>
                    <source src={THE_VIDEO} type="video/mp4" />
                    <img
                      src={wide_range_thumbnail.url}
                      alt="image"
                      className="img-fluid"
                    />
                  </video>
                </div>
                <div className="col-md-5">
                  <div className="content-desc">
                    <div className="marTop40">
                      <ul className="">
                        {wide_range_features.map((feature, index) => (
                          <li
                            key={"dddf" + index}
                            className="wideChoicesList d-flex"
                          >
                            <div className="icon">
                              <Link to={feature.range_link.text}>
                                <div
                                  role="button"
                                  tabIndex={index}
                                  className="iconCard shaded-circle"
                                  onMouseEnter={() =>
                                    setFeaturesIcons(
                                      featuresIcons.map((icon, i) =>
                                        index === i
                                          ? TRADINGOPTIONFRONTIMAGES[i]
                                          : icon
                                      )
                                    )
                                  }
                                  onMouseLeave={() =>
                                    setFeaturesIcons(
                                      featuresIcons.map((icon, i) =>
                                        index === i
                                          ? TRADINGOPTIONIMAGES[i]
                                          : icon
                                      )
                                    )
                                  }
                                >
                                  <img
                                    src={featuresIcons[index]}
                                    alt="Card Back"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="contents">
                              <h6 className="m-bottom-10">
                                {feature.range_title.text}
                              </h6>
                              <p>{feature.range_feature_description.text}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row align-items-center tradingNextLevel">
                <div className="col-lg-5 order-lg-0 order-sm-1">
                  <div className="content-desc">
                    <h4>{next_level.text}</h4>
                    <p>{next_level_description.text}</p>
                    <Link to={registerLiveUrl}>
                      <div className="card123 d-flex justify-content-center py-4">
                        {/* <img src={nextLevelImage} alt="Card Back" /> */}
                        <button
                          type="button"
                          className="btn btn-block-orange no-stretch side-padding bold-front"
                        >
                          {next_level_button_label.text}
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 order-lg-1 order-sm-0 margin-md-60">
                  <img src={BEAR_BULLS} alt="image" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinAccuIndex;
